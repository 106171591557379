export function getNotificationPermission() {
  return new Promise((resolve, reject) => {
    Notification.requestPermission()
      .then((data) => {
        if (data === 'granted') {
          return resolve()
        } else {
          reject(new Error('Permission Denied'))
        }
      })
      .catch(reject)
  })
}

function playAudio() {
  new Audio(`/sound/notification.mp3`).play()
}

export function showDesktopNotification(title, description, clickHandler) {
  getNotificationPermission().then(() => {
    const notification = new Notification(title, {
      body: description,
      icon: `/images/motadata-notification.png`,
    })
    notification.addEventListener('click', () => {
      clickHandler()
      notification.close()
    })
    playAudio()
  })
}
