<template>
  <FlotoContentLoader :loading="loading">
    <div
      v-if="barcodesChunk.length"
      id="printableArea"
      class="flex flex-1 flex-col"
    >
      <div
        v-for="(barcodes, index) in barcodesChunk"
        :key="index"
        class="flex"
        :style="{ marginBottom: getMargin }"
      >
        <div
          v-for="barcode in barcodes"
          :key="barcode"
          :style="{
            width: getWidth,
            height: getHeight,
            marginRight: getMargin,
            backgroundImage: `url(${generateImageString(barcode)})`,
            backgroundSize: `${getWidth} ${getHeight}`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            border: '2px dashed #ccc',
          }"
          class="cursor-pointer"
        />
      </div>
    </div>
    <div
      v-if="prepairedAssets.length"
      id="printableArea"
      class="flex flex-1 flex-col"
    >
      <div
        v-for="(asset, index) in prepairedAssets"
        :key="index"
        :style="{
          marginBottom: getMargin,
          width: getWidth,
          border: '2px dashed #ccc',
          padding: '5px',
        }"
      >
        <div
          v-for="(value, key) in asset"
          :key="key"
          style="word-wrap: break-word"
        >
          <template v-if="key && excludedFields.indexOf(key) === -1">
            <b>{{ key }}:</b>
            {{ value }}
          </template>
        </div>
        <div
          :style="{
            width: '100%',
            height: getHeight,
            backgroundImage: `url(${generateImageString(asset.barCode)})`,
            backgroundSize: `${getWidth} ${getHeight}`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }"
          class="cursor-pointer"
        />
      </div>
    </div>
  </FlotoContentLoader>
</template>

<script>
import { printElement } from '@utils/print'
import { generateWithConfig } from '@utils/barcode'
import Chunk from 'lodash/chunk'
import {
  getBarcodeConfigurationApi,
  getBarcodeDetailsByAssetIdsApi,
} from '@modules/asset-management/api/barcode-api'
export default {
  name: 'PrintBarcode',
  data() {
    this.excludedFields = ['id', 'barCode']
    this.defaultConfig = {
      width: 1,
      height: 75,
      displayValue: true,
      margin: 10,
      fontSize: 12,
      background: '#dee5ed',
    }
    return {
      config: {},
      assetDetails: [],
      barcodesChunk: [],
      prepairedAssets: [],
      assetBarcodeFields: [],
      loading: true,
    }
  },
  computed: {
    getMargin() {
      return `${this.config.gap}mm`
    },
    getWidth() {
      return `${this.config.width}in`
    },
    getHeight() {
      return `${this.config.height}in`
    },
    barWidth() {
      let width = 2
      if (this.config.width < 2) {
        width = 1
      }
      if (this.config.width >= 2) {
        width = 1.3
      }
      if (this.config.width >= 3) {
        width = 1.3
      }
      if (this.config.width >= 4) {
        width = 2
      }
      if (this.config.width >= 5) {
        width = 2.5
      }
      if (this.config.width >= 7) {
        width = 3
      }
      return width
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(newValue, prevValue) {
        if (prevValue === true && newValue === false) {
          setTimeout(() => {
            printElement(
              document.getElementById('printableArea'),
              false,
              window
            )
          }, 500)
        }
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      getBarcodeConfigurationApi().then((data) => {
        const urlParams = new URLSearchParams(window.location.search)
        const barcodes = urlParams.get('barcodes')
        const assetIds = urlParams.get('assetIds')
        const moduleName = urlParams.get('moduleName')
        const assetDetails = [
          ...data.barcodeMandatoryPropKeys,
          ...data.barcodeCustomfieldKeys,
        ]
        this.assetDetails = assetDetails
        if (barcodes) {
          const b = JSON.parse(barcodes)
          this.barcodesChunk = Chunk(b, data.labelsPerRow || 2)
          this.loading = false
        } else if (assetIds && moduleName) {
          const ids = JSON.parse(assetIds)
          getBarcodeDetailsByAssetIdsApi(moduleName, ids).then((data) => {
            if (assetDetails.length) {
              this.prepairedAssets = data
            } else {
              this.barcodesChunk = Chunk(
                data.map((i) => i.barCode),
                data.labelsPerRow || 2
              )
            }
            this.loading = false
          })
        }

        this.config = data
      })
    },
    generateImageString(barcode) {
      const scale = Math.round((this.config.width * 4) / 2)
      const height = Math.round(this.config.height * 18)
      const url = generateWithConfig(
        barcode,
        {
          scale,
          height,
        },
        this.config.width,
        this.config.height
      )
      return url
    },
  },
}
</script>
