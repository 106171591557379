<template>
  <FlotoContentLoader :loading="loading">
    <div id="printableArea" class="flex flex-1 flex-col">
      <div
        v-for="(qrcode, index) in qrcodeDetails"
        :key="index"
        class="flex items-start m-4"
      >
        <img :src="qrcode.src" style="border: 1px solid white" />
        <div class="flex flex-col ml-4">
          <div v-for="(details, key) in qrcode.data" :key="key">
            <template v-if="key !== 'model'">
              <b>{{ key }}:</b> {{ details }}
            </template>
          </div>
        </div>
      </div>
    </div>
  </FlotoContentLoader>
</template>

<script>
import { printElement } from '@utils/print'
import { generateQrCodeForAssetUsingQrData } from '@utils/qrcode'
import { generateQrCodeApi } from '@src/modules/asset/asset-api'
export default {
  name: 'PrinQrcode',
  data() {
    return {
      loading: true,
      qrcodeDetails: [],
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newValue, prevValue) {
        if (prevValue === true && newValue === false) {
          setTimeout(() => {
            printElement(
              document.getElementById('printableArea'),
              false,
              window
            )
          }, 500)
        }
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      const urlParams = new URLSearchParams(window.location.search)
      const assetIds = urlParams.get('assetIds')
      const moduleName = urlParams.get('moduleName')
      const ids = JSON.parse(assetIds)
      generateQrCodeApi(moduleName, ids).then((data) => {
        const allQrcodeData = []
        data.forEach((d) => {
          allQrcodeData.push(generateQrCodeForAssetUsingQrData(d, true))
        })
        Promise.all(allQrcodeData).then((response) => {
          this.qrcodeDetails = response
        })
        this.loading = false
      })
    },
  },
}
</script>
