import Moment from 'moment'

export const getDiff = (value, unit = 'millisecond') => {
  if (value === 0) {
    return 0
  }
  const actual = Moment.unix(Math.abs(value) / 1000)
  const today = Moment()
  const diff = actual.diff(today, unit)
  return diff
}
export function isOverDue(value) {
  if (!value) {
    return false
  }
  if (value > 0 && getDiff(value) < 0) {
    return true
  }
  return false
}
