export function convertIpToNumeric(ipAddress) {
  const arrIp = ipAddress.split('.')

  const segment1 = parseInt(arrIp[0])
  const segment2 = parseInt(arrIp[1])
  const segment3 = parseInt(arrIp[2])
  const segment4 = parseInt(arrIp[3])

  // reverse order calc
  // eg. 255.255.240.0     &lt;-- start at the end
  // 0 + (240*256) + (255*65536) + (255*16777216)
  const calc =
    segment4 + segment3 * 256 + segment2 * 65536 + segment1 * 16777216

  return calc
}

function getPadValue(str) {
  const pad = '0000'
  const padValue = pad.substring(0, pad.length - str.length) + str
  return padValue
}

export function hexToDecimal(ipAddress) {
  const arrIp = ipAddress.split(':')

  let segment8 = `${arrIp[7]}`
  if (!segment8) {
    segment8 = parseInt('0000')
  }
  const intValue = parseInt(`${getPadValue(segment8)}`, 16)
  return intValue
}

export function checkVersion(ipAddress) {
  let version
  if (ipAddress.indexOf('.') >= 0) {
    version = 'ipv4'
  }
  if (ipAddress.indexOf(':') >= 0) {
    version = 'ipv6'
  }
  return version
}

export function checkValidRange(start, end) {
  const version = checkVersion(start)
  const endVersion = checkVersion(end)
  if (version !== endVersion) {
    return false
  }
  if (version === 'ipv4') {
    const startRangeInLong = convertIpToNumeric(start)
    const valueInLong = convertIpToNumeric(end)
    const diff = valueInLong - startRangeInLong
    if (diff > 0 && diff <= 255) {
      return true
    }
    return false
  }
  if (version === 'ipv6') {
    if (start === end) {
      return false
    }
    const startArray = start
      .split(':')
      .map((i) => (i ? getPadValue(i) : '0000'))
      .slice(0, 7)
    const endArray = end
      .split(':')
      .map((i) => (i ? getPadValue(i) : '0000'))
      .slice(0, 7)
    const startString = startArray.toString('')
    const endString = endArray.toString('')
    const startRangeInLong = hexToDecimal(start)
    const valueInLong = hexToDecimal(end)
    const diff = valueInLong - startRangeInLong
    if (startString === endString && diff >= 0 && diff <= 999) {
      return true
    }
    return false
  }
}

// export function convertNumericToIp(bigNumber) {
//   const Octet1 = Math.floor(bigNumber / 16777216)
//   let RestOfIP = bigNumber - Octet1 * 16777216

//   const Octet2 = Math.floor(RestOfIP / 65536)
//   let RestOfIP = RestOfIP - Octet2 * 65536

//   const Octet3 = Math.floor(RestOfIP / 256)

//   const Octet4 = RestOfIP - Octet3 * 256

//   const returnValue = Octet1 + '.' + Octet2 + '.' + Octet3 + '.' + Octet4

//   return returnValue
// }
