import store from '@state/store'

export function token() {
  return store.state.auth.token && store.state.auth.token.access_token
}

export function publicToken() {
  return (
    store.state.auth.publicToken && store.state.auth.publicToken.access_token
  )
}

export function isLoggedIn() {
  return Boolean(store.state.auth.user)
}
export function isPortalLogin() {
  return store.state.auth.loginType === 'support-portal'
}
export function logout() {
  return store.dispatch('auth/logout')
}

export function getCurrentUser() {
  return store.state.auth.user || {}
}

export function getSamlRequest() {
  return store.state.auth.samlRequest || {}
}

export function isCxoUser() {
  return Boolean((store.state.auth.user || {}).cxoUser)
}

export function isCxoTechnician() {
  return isCxoUser() && !isPortalLogin()
}

export function getCurrentOrganization() {
  return store.state.organization.organization || {}
}

export function getPriorities() {
  return store.state.priority.priorities || []
}

export function getLeaveTypes() {
  return store.state.leaveType.leaveTypes || []
}
export function theme() {
  return store.state.preference.theme
}
export function getAssetCiTypes(moduleName) {
  if (moduleName === 'cmdb') {
    return store.state.ciType.ciTypes || []
  }
  return store.state.assetType.assetTypes || []
}
