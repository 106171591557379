let FuseFn

import(/* webpackChunkName: "utils" */ 'fuse.js').then((c) => {
  FuseFn = c.default || c
})

export function move(arr, oldIndex, newIndex) {
  while (oldIndex < 0) {
    oldIndex += arr.length
  }
  while (newIndex < 0) {
    newIndex += arr.length
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length
    while (k-- + 1) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}
export function addElement(arr, newIndex, element) {
  let result = []
  if (newIndex === 0) {
    result = arr.unshift(element)
  }
  if (newIndex > 0) {
    result = [...arr.splice(0, newIndex - 1), element, ...arr.splice(newIndex)]
  }
  return result
}

export function removeElement(arr, oldIndex, element) {
  // let result = []
  // if (oldIndex === 0) {
  //   result = arr.shift()
  // }
  // if (oldIndex > 0) {
  //   result = [...arr.splice(0, oldIndex + 1), ...arr.splice(oldIndex)]
  // }
  return [...arr.splice(0, oldIndex + 1), ...arr.splice(oldIndex)]
}

export function searchList(arr, term, key = 'name', threshold = 0.2) {
  const fuse = new FuseFn(arr, {
    threshold,
    ...(key
      ? {
          keys: Array.isArray(key) ? key : [key],
        }
      : {}),
  })
  return fuse.search(term)
}
