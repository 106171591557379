class ScopedEval {
  /** @param {Record<string,unknown>} scope */
  constructor(scope) {
    this.scope = scope
  }
  eval(__script) {
    // eslint-disable-next-line
    return new Function(
      ...Object.keys(this.scope),
      `   return new Promise((resolve) => {
        resolve(eval(
            '"use strict";delete this.__script;'
            + this.__script
        ));
      })
              `.replace(/\t/, '')
    ).bind({
      __script,
    })(...Object.values(this.scope))
  }
}

export default function scriptEvaluator(script, context) {
  const evaluator = new ScopedEval(context)
  return evaluator.eval(script)
}
