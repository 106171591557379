export const LANGUAGE_MAP = {
  'en-US': 'en',
  'gu-GU': 'gu',
  'tl-PH': 'tl',
  'ms-MY': 'ms',
  'th-TH': 'th',
  'in-ID': 'id',
  'ar-AE': 'ar',
  'es-ES': 'es',
  'fr-CH': 'fr',
  'hi-IN': 'hi',
  'pt-PT': 'pt',
  'tr-TR': 'tr',
}
