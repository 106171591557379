export async function generateFingerprint() {
  const fingerprintComponents = [
    navigator.userAgent, // User Agent
    navigator.platform, // Operating System
    screen.width + 'x' + screen.height, // Screen Resolution
    screen.colorDepth, // Color Depth
    new Date().getTimezoneOffset(), // Timezone Offset
    navigator.language, // Language
    navigator.languages, // Languages List
    !!window.sessionStorage, // Support for Session Storage
    !!window.localStorage, // Support for Local Storage
    !!window.indexedDB, // Support for IndexedDB
    !!window.WebGLRenderingContext, // Support for WebGL
    getCanvasFingerprint(), // Canvas Fingerprinting
    getWebGLFingerprint(), // WebGL Fingerprinting
  ]

  const fingerprintString = fingerprintComponents.join('###')
  const fingerprintHash = await hashString(fingerprintString)
  window.fingerprint = fingerprintHash
  return fingerprintHash
}

function getCanvasFingerprint() {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.textBaseline = 'top'
  context.font = '14px Arial'
  context.fillStyle = 'black'
  context.fillText('Browser Fingerprint', 0, 0)

  return canvas.toDataURL()
}

function getWebGLFingerprint() {
  const canvas = document.createElement('canvas')
  const gl =
    canvas.getContext('webgl') || canvas.getContext('experimental-webgl')

  if (!gl) {
    return 'no-webgl'
  }

  const debugInfo = gl.getExtension('WEBGL_debug_renderer_info')
  const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL)
  const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL)

  return `${renderer}###${vendor}`
}

async function hashString(str) {
  const msgBuffer = new TextEncoder().encode(str)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}
