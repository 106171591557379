<template>
  <span>Loading...</span>
</template>
<script>
export default {
  name: 'OAuthCallback',
  created() {
    if (this.$route.query.code) {
      localStorage.setItem('app:oauth.code', this.$route.query.code)
    }
    if (this.$route.query.error) {
      this.$router.replace({
        name: '500',
        query: { message: this.$route.query.error },
      })
    }
  },
}
</script>
