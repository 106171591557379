import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import Constants from '@constants'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export const SHORTCUT_MAP = () => [
  {
    key: ['alt+f1'],
    keyDescription: '<code>Alt+f1</code>',
    command: 'show_shortcut_help',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules) {
      return true
    },
    description: `${__rootT('shortcutHelpers.view_available')}`,
  },
  {
    key: ['c r'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>r</code>`,
    command: 'create_request',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules) {
      if (
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('create')} ${__rootT('new')} ${__rootTc(
      'incident'
    )}`,
    routeArgs: (isPortal) => {
      if (isPortal) {
        return ['support-portal', 'create-request']
      }
      return [
        'ticket',
        'create',
        {
          params: { ticketType: Constants.REQUEST },
        },
      ]
    },
  },
  {
    key: ['c p'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>p</code>`,
    command: 'create_problem',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.PROBLEM) === -1 ||
        userModules.indexOf(Constants.PROBLEM) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('create')} ${__rootT('new')} ${__rootTc(
      'problem'
    )}`,
    routeArgs: [
      'ticket',
      'create',
      {
        params: { ticketType: Constants.PROBLEM },
      },
    ],
  },
  {
    key: ['c c'],
    keyDescription: `<code>c</code>  ${__rootTc('then')} <code>c</code>`,
    command: 'create_change',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.CHANGE) === -1 ||
        userModules.indexOf(Constants.CHANGE) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('create')} ${__rootT('new')} ${__rootTc('change')}`,
    routeArgs: [
      'ticket',
      'create',
      {
        params: { ticketType: Constants.CHANGE },
      },
    ],
  },
  {
    key: ['c l'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>l</code>`,
    command: 'create_release',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.RELEASE) === -1 ||
        userModules.indexOf(Constants.RELEASE) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('create')} ${__rootT('new')} ${__rootTc(
      'release'
    )}`,
    routeArgs: [
      'ticket',
      'create',
      {
        params: { ticketType: Constants.RELEASE },
      },
    ],
  },
  {
    key: ['c s'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>s</code>`,
    command: 'create_service_request',
    groups: ['navigate'],
    supportedModules: function (
      licenseModules,
      userModules,
      isPortal,
      { allowRequesterToAccessServiceRequest }
    ) {
      if (!allowRequesterToAccessServiceRequest) {
        return false
      }
      if (
        isPortal &&
        licenseModules.indexOf(Constants.SERVICE_CATALOG) !== -1
      ) {
        return true
      }
      if (
        licenseModules.indexOf(Constants.SERVICE_CATALOG) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('create')} ${__rootT('new')} ${__rootTc(
      'service_request'
    )}`,
    routeArgs: (isPortal) => {
      if (isPortal) {
        return ['support-portal', 'service-list']
      }
      return ['service-catalog', 'request']
    },
  },
  {
    key: ['c a'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>a</code>`,
    command: 'create_asset',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.ASSET) === -1 ||
        userModules.indexOf(Constants.ASSET) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('create')} ${__rootT('new')} ${__rootTc('asset')}`,
    routeArgs: ['asset', 'create'],
  },
  {
    key: ['g i'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>i</code>`,
    command: 'go_to_request',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules) {
      if (
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('request') })}`,
    routeArgs: (isPortal) => {
      if (isPortal) {
        return [
          'support-portal',
          'my-requests',
          { params: { ticketType: Constants.ASSET } },
        ]
      }
      return [
        'ticket',
        undefined,
        {
          params: { ticketType: Constants.REQUEST },
        },
      ]
    },
  },
  {
    key: ['g p'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>p</code>`,
    command: 'go_to_problem',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.PROBLEM) === -1 ||
        userModules.indexOf(Constants.PROBLEM) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('problem') })}`,
    routeArgs: [
      'ticket',
      undefined,
      {
        params: { ticketType: Constants.PROBLEM },
      },
    ],
  },
  {
    key: ['g c'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>c</code>`,
    command: 'go_to_change',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.CHANGE) === -1 ||
        userModules.indexOf(Constants.CHANGE) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('change') })}`,
    routeArgs: [
      'ticket',
      undefined,
      {
        params: { ticketType: Constants.CHANGE },
      },
    ],
  },
  {
    key: ['g l'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>l</code>`,
    command: 'go_to_release',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.RELEASE) === -1 ||
        userModules.indexOf(Constants.RELEASE) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('release') })}`,
    routeArgs: [
      'ticket',
      undefined,
      {
        params: { ticketType: Constants.RELEASE },
      },
    ],
  },
  {
    key: ['g a'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>a</code>`,
    command: 'go_to_asset',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.ASSET) === -1 ||
        userModules.indexOf(Constants.ASSET) === -1
      ) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('asset') })}`,
    routeArgs: (isPortal) => {
      if (isPortal) {
        return ['support-portal', 'my-assets']
      }
      return [
        'asset',
        undefined,
        {
          params: { assetType: Constants.ASSET_HARDWARE },
        },
      ]
    },
  },
  {
    key: ['g k'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>k</code>`,
    command: 'go_to_knowledge',
    groups: ['navigate'],
    supportedModules: function (
      licenseModules,
      userModules,
      isPortal,
      { allowRequesterToAccessKB }
    ) {
      if (!allowRequesterToAccessKB) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('knowledge') })}`,
    routeArgs: (isPortal) => {
      if (isPortal) {
        return ['support-portal', 'all-articles']
      }
      return ['knowledge']
    },
  },
  {
    key: ['g r'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>r</code>`,
    command: 'go_to_report',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return userModules.indexOf(Constants.REPORT) >= 0
    },
    description: `${__rootT('go_to', { resource: __rootTc('report', 2) })}`,
    routeArgs: ['reports'],
  },
  {
    key: ['g m'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>m</code>`,
    command: 'go_to_approvals',
    groups: ['navigate'],
    supportedModules: function (
      licenseModules,
      userModules,
      isPortal,
      { allowRequesterToAccessMyApprovals }
    ) {
      if (!allowRequesterToAccessMyApprovals) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', {
      resource: __rootTc('my_approval', 2),
    })}`,
    routeArgs: (isPortal) => {
      if (isPortal) {
        return ['support-portal', 'my-approvals']
      }
      return ['my-approval']
    },
  },
  {
    key: ['g t'],
    keyDescription: `<code>g</code> ${__rootTc('then')} <code>t</code>`,
    command: 'go_to_tasks',
    groups: ['navigate'],
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return true
    },
    description: `${__rootT('go_to', { resource: __rootTc('my_tasks') })}`,
    routeArgs: ['my-tasks'],
  },
  {
    key: ['up'],
    keyDescription: '<code>Up</code>',
    command: 'move_up',
    groups: ['list-view'],
    description: __rootT('shortcutHelpers.up'),
  },
  {
    key: ['down'],
    keyDescription: '<code>Down</code>',
    command: 'move_down',
    groups: ['list-view'],
    description: __rootT('shortcutHelpers.down'),
  },
  {
    key: ['enter'],
    keyDescription: '<code>Enter</code>',
    command: 'enter',
    groups: ['list-view'],
    description: __rootT('shortcutHelpers.enter'),
  },
  {
    key: ['space'],
    keyDescription: '<code>Space</code>',
    command: 'space',
    groups: ['list-view'],
    description: __rootT('shortcutHelpers.space'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return true
    },
  },
  {
    key: ['ctrl+right'],
    keyDescription: '<code>Ctrl+Right</code>',
    command: 'next-page',
    groups: ['list-view', 'detail-page'],
    description(groupName) {
      if (groupName === 'list-view') {
        return __rootT('shortcutHelpers.next-page')
      }
      return __rootT('shortcutHelpers.next-ticket')
    },
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return true
    },
  },
  {
    key: ['ctrl+left'],
    keyDescription: '<code>Ctrl+Left</code>',
    command: 'previous-page',
    groups: ['list-view', 'detail-page'],
    description(groupName) {
      if (groupName === 'list-view') {
        return __rootT('shortcutHelpers.previous-page')
      }
      return __rootT('shortcutHelpers.previous-ticket')
    },
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return true
    },
  },
  {
    key: ['c t'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>t</code>`,
    command: 'create-task',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.create-task'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return (
        licenseModules.indexOf(Constants.REQUEST) >= 0 ||
        licenseModules.indexOf(Constants.PROBLEM) >= 0 ||
        licenseModules.indexOf(Constants.CHANGE) >= 0
      )
    },
  },
  {
    key: ['c w'],
    keyDescription: `<code>c</code> ${__rootTc('then')} <code>w</code>`,
    command: 'create-worklog',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.create-worklog'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return (
        licenseModules.indexOf(Constants.REQUEST) >= 0 ||
        licenseModules.indexOf(Constants.PROBLEM) >= 0 ||
        licenseModules.indexOf(Constants.CHANGE) >= 0
      )
    },
  },
  {
    key: ['~'],
    keyDescription: '<code>~</code>',
    command: 'close-ticket',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.close-ticket'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal) {
        return false
      }
      return (
        licenseModules.indexOf(Constants.REQUEST) >= 0 ||
        licenseModules.indexOf(Constants.PROBLEM) >= 0 ||
        licenseModules.indexOf(Constants.CHANGE) >= 0
      )
    },
  },
  {
    key: ['!'],
    keyDescription: '<code>!</code>',
    command: 'mark-spam',
    preCommand: 'dropdown-open',
    preCommandGap: '400',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.mark-spam'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
  },
  {
    key: ['del'],
    keyDescription: '<code>Delete</code>',
    command: 'archive',
    preCommand: 'dropdown-open',
    preCommandGap: '400',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.archive'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal || licenseModules.indexOf(Constants.REQUEST) === -1) {
        return false
      }
      return true
    },
  },
  {
    key: ['w'],
    keyDescription: '<code>w</code>',
    command: 'watch',
    preCommand: 'dropdown-open',
    preCommandGap: '400',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.watch'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
  },
  {
    key: ['o'],
    keyDescription: '<code>o</code>',
    command: 'scenario',
    preCommand: 'dropdown-open',
    preCommandGap: '400',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.scenario'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
  },
  {
    key: ['r'],
    keyDescription: '<code>r</code>',
    command: 'reply',
    preCommand: 'conversation',
    preCommandGap: '100',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.reply'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      return (
        licenseModules.indexOf(Constants.REQUEST) >= 0 ||
        licenseModules.indexOf(Constants.PROBLEM) >= 0 ||
        licenseModules.indexOf(Constants.CHANGE) >= 0
      )
    },
  },
  {
    key: ['b'],
    keyDescription: '<code>b</code>',
    command: 'collaborate',
    preCommand: 'conversation',
    preCommandGap: '100',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.collaborate'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal || licenseModules.indexOf(Constants.REQUEST) === -1) {
        return false
      }
      return true
    },
  },
  {
    key: ['n'],
    keyDescription: '<code>n</code>',
    command: 'note',
    preCommand: 'conversation',
    preCommandGap: '100',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.note'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal || licenseModules.indexOf(Constants.REQUEST) === -1) {
        return false
      }
      return true
    },
  },
  {
    key: ['d'],
    keyDescription: '<code>d</code>',
    command: 'diagnosis',
    preCommand: 'conversation',
    preCommandGap: '100',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.diagnosis'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
  },
  {
    key: ['s'],
    keyDescription: '<code>s</code>',
    command: 'solution',
    preCommand: 'conversation',
    preCommandGap: '100',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.solution'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (isPortal || licenseModules.indexOf(Constants.REQUEST) === -1) {
        return false
      }
      return true
    },
  },
  {
    key: ['f'],
    keyDescription: '<code>f</code>',
    command: 'forward',
    preCommand: 'conversation',
    preCommandGap: '100',
    groups: ['detail-page'],
    description: __rootT('shortcutHelpers.forward'),
    supportedModules: function (licenseModules, userModules, isPortal) {
      if (
        isPortal ||
        licenseModules.indexOf(Constants.REQUEST) === -1 ||
        userModules.indexOf(Constants.REQUEST) === -1
      ) {
        return false
      }
      return true
    },
  },
]
