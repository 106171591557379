<template>
  <div class="h-full w-full flex bg-white p-4">
    <div v-if="data.noData" class="flex flex-col flex-1">
      <FlotoNoData class="w-full">
        <template v-slot:message>
          {{ $tc('no_chart_data') }}
        </template>
      </FlotoNoData>
    </div>
    <ChartOptions
      v-else
      :key="type"
      :data="data.series"
      :widget-type="type"
      :widget="widget"
      :vertical="vertical"
      :enable-export="false"
      :categories="(data || {}).categories || []"
    >
      <template v-slot="{ options }">
        <Chart
          v-if="options.series.length"
          :options="options"
          class="flex-1 h-full w-full"
        />
        <div v-else class="flex flex-col flex-1 items-center justify-center">
          <MLoader />
          <h5>Building Chart...</h5>
        </div>
      </template>
    </ChartOptions>
  </div>
</template>

<script>
import Chart from '@components/chart/chart.vue'
import ChartOptions from '@/src/components/chart/options/chart-options.vue'
import buildChartSeries from '@components/chart/chart-series-builder'
import { getReportPreviewOnlyApi } from '@modules/reports/reports-api'
import { WidgetTypeConstants } from '@components/chart/helper'

export default {
  name: 'ChartRender',
  components: { Chart, ChartOptions },
  data() {
    return {
      type: undefined,
      data: undefined,
    }
  },
  computed: {
    vertical() {
      if ([WidgetTypeConstants.VERTICAL_BAR].indexOf(this.type) >= 0) {
        return true
      }
      return false
    },
  },
  created() {
    const query = this.$route.query
    if (!query.moduleName || !query.token || !query.reportId) {
      throw new Error('Params moduleName, token, reportId is required')
    }
    this.getReportChart(query)
  },
  methods: {
    getReportChart(query) {
      const requestedQuery = {
        ...query,
        ...(query.startDate ? { startDate: +query.startDate } : {}),
        ...(query.endDate ? { endDate: +query.endDate } : {}),
      }
      return getReportPreviewOnlyApi(
        this.$constants.REPORT,
        requestedQuery
      ).then((preview) => {
        this.type = query.chartType
        this.data = buildChartSeries(preview, this.type)
      })
    },
  },
}
</script>
