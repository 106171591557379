import bytesPerSecToSize, { bytesToSize } from '@src/filters/bytes'
import Duration from '@src/filters/duration'

export function isUnitConvertible(counter) {
  if (
    counter.indexOf('.percent.') >= 0 ||
    counter.indexOf('.percentage') >= 0
  ) {
    return true
  } else if (counter.indexOf('.bytes.') >= 0) {
    return true
  } else if (
    counter.indexOf('.ms.') >= 0 ||
    counter.indexOf('.time') >= 0 ||
    counter.indexOf('.sec.') >= 0 ||
    counter.indexOf('duration.') >= 0
  ) {
    return true
  }
  return false
}

export function applyUnit(counter, value) {
  if (
    counter.indexOf('.percent.') >= 0 ||
    counter.indexOf('.percentage') >= 0
  ) {
    return `${value || 0}%`
  } else if (counter.indexOf('.bytes.') >= 0 && counter.indexOf('.sec') >= 0) {
    return bytesPerSecToSize(value, undefined)
  } else if (counter.indexOf('.bytes.') >= 0) {
    return bytesToSize(value, undefined)
  } else if (
    counter.indexOf('.ms.') >= 0 ||
    counter.indexOf('.time') >= 0 ||
    counter.indexOf('.sec.') >= 0 ||
    counter.indexOf('duration.') >= 0
  ) {
    const initialUnit =
      counter.indexOf('.ms.') >= 0 ? 'milliseconds' : undefined
    return Duration(
      value,
      initialUnit,
      counter.indexOf('.ms.') >= 0
        ? false
        : counter.indexOf('.time.') >= 0 || counter.indexOf('duration.') >= 0,
      undefined,
      counter.indexOf('.ms.') >= 0
    )
  }
  return value
}

export function getUnit(counter, value) {
  if (
    counter.indexOf('.percent.') >= 0 ||
    counter.indexOf('.percentage') >= 0
  ) {
    return `%`
  } else if (counter.indexOf('.bytes.') >= 0 && counter.indexOf('.sec.') >= 0) {
    const v = bytesPerSecToSize(value)
    if (v) {
      return v.replace(/^\d+(?:\.\d+)?\s?(\w+)$/, '$1')
    }
  } else if (counter.indexOf('.bytes.') >= 0) {
    const v = bytesToSize(value)
    if (v) {
      return v.replace(/^\d+(?:\.\d+)?\s?(\w+)$/, '$1')
    }
  } else if (
    counter.indexOf('.ms.') >= 0 ||
    counter.indexOf('.time') >= 0 ||
    counter.indexOf('.sec.') >= 0 ||
    counter.indexOf('duration.') >= 0
  ) {
    const initialUnit =
      counter.indexOf('.ms.') >= 0 ? 'milliseconds' : undefined
    const v = Duration(value, initialUnit, false)
    if (v) {
      return v.replace(/^\d+(?:\.\d+)?\s?(\w+)$/, '$1')
    }
  }
  return undefined
}

export function getAllowedUnit(counter = '') {
  if (
    counter.indexOf('.percent.') >= 0 ||
    counter.indexOf('.percentage') >= 0
  ) {
    return `percent`
  } else if (counter.indexOf('.bytes.') >= 0 && counter.indexOf('.sec.') >= 0) {
    return 'bps'
  } else if (counter.indexOf('.bytes.') >= 0) {
    return 'bytes'
  } else if (
    counter.indexOf('.ms.') >= 0 ||
    counter.indexOf('.time') >= 0 ||
    counter.indexOf('.sec.') >= 0 ||
    counter.indexOf('duration.') >= 0
  ) {
    return counter.indexOf('.ms') >= 0 ? 'ms' : 's'
  }
  return undefined
}
