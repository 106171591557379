<template>
  <div class="h-100">
    <System404 v-if="!isCustom" v-bind="$attrs" v-on="$listeners" />
    <Custom404
      v-if="isCustom"
      :page-content="pageContent"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import System404 from './_404/system-404.vue'
import Custom404 from './_404/custom-404.vue'
import BrandingMixin from '@src/mixins/branding'
import { QUERY_STRING_TEXT } from '@src/js/static-page-script'

export default {
  name: 'Error404',
  components: { System404, Custom404 },
  mixins: [BrandingMixin],
  page: {
    title: '404NotFound',
    meta: [{ name: 'description', content: '404' }],
  },
  data() {
    return {
      isCustom: false,
      pageContent: undefined,
    }
  },
  watch: {
    'branding.pagesCustomization': {
      immediate: true,
      handler(newValue) {
        if (
          this.$route.query[QUERY_STRING_TEXT] &&
          this.$route.query[QUERY_STRING_TEXT] === 'true'
        ) {
          return
        }
        const currentPage = newValue.find((v) => v.pageType === 'page_404')
        if (currentPage.enabled && currentPage.pageContent) {
          this.isCustom = true
          this.pageContent = currentPage.pageContent
        }
      },
    },
  },
}
</script>
