import { extend, setInteractionMode } from 'vee-validate'
import Moment from 'moment'
import isURL from 'validator/lib/isURL'
import isIP from 'validator/lib/isIP'
import isFQDN from 'validator/lib/isFQDN'
import {
  required,
  email,
  min,
  max,
  numeric,
  // eslint-disable-next-line
  min_value,
  // eslint-disable-next-line
  max_value,
  confirmed,
  regex,
} from 'vee-validate/dist/rules'
// import en from 'vee-validate/dist/locale/en'
import { checkValidRange } from '@utils/ip'
import { getRootTranslator } from '@utils/get-module-translator'
const __rootT = getRootTranslator()

export const emailRegEx =
  /^(?=.{1,255}$)[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/

setInteractionMode('flotoValidationMode', ({ errors }) => {
  if (errors.length) {
    return {
      on: ['input', 'blur', 'update', 'change'],
    }
  }
  return {
    on: ['change', 'blur'],
  }
})

export { email }
extend('required', {
  ...required,
  // message: en.messages.required.replace('The', ''),
  message(field) {
    return __rootT('veevalidaion_messages.required', { field })
  },
})
extend('email', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp(emailRegEx),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.email', { field })
  },
})
extend('min', {
  ...min,
  message(field, args) {
    return __rootT('veevalidaion_messages.min', { field, length: args.length })
  },
})
extend('max', {
  ...max,
  message(field, args) {
    return __rootT('veevalidaion_messages.max', { field, length: args.length })
  },
})
extend('confirmed', {
  ...confirmed,
  message(field) {
    return __rootT('veevalidaion_messages.confirmed', { field })
  },
})
extend('numeric', {
  ...numeric,
  message(field) {
    return __rootT('veevalidaion_messages.confirmed', { field })
  },
})

extend('min_max_valid_input', {
  ...numeric,
  message(field) {
    return __rootT('veevalidaion_messages.valid_input_value', { field })
  },
})

extend('url', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }
    return isURL(value)
  },
  message(field) {
    return __rootT('veevalidaion_messages.url', { field })
  },
})
extend('ip_or_domain', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }
    return isIP(value) || isFQDN(value, {})
  },
  message(field) {
    return __rootT('veevalidaion_messages.ip_or_domain', { field })
  },
})
extend('ip', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }
    return isIP(value)
  },
  message(field) {
    return __rootT('veevalidaion_messages.ip', { field })
  },
})

extend('domain', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }
    return isFQDN(value)
  },
  message(field) {
    return __rootT('veevalidaion_messages.domain', { field })
  },
})

extend('nonzero', {
  validate: (value) => value !== 0,
  message(field) {
    return __rootT('veevalidaion_messages.required', { field })
  },
})

extend('nonzero_string', {
  validate: (value) => value !== 0 && value !== '0',
  message(field) {
    return __rootT('veevalidaion_messages.required', { field })
  },
})

extend('date_required', {
  validate: (value) => value && value !== undefined && value !== 0,
  message(field) {
    return __rootT('veevalidaion_messages.required', { field })
  },
})

extend('short_text', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }
    return max.validate(value, { length: 100 })
  },
  message(field) {
    return __rootT('veevalidaion_messages.short_text', {
      field,
      length: 100,
    })
  },
})

extend('subject', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }
    return max.validate(value, { length: 255 })
  },
  message(field) {
    return __rootT('veevalidaion_messages.short_text', {
      field,
      length: 255,
    })
  },
})

extend('description', {
  validate(value) {
    return true
    // if (value === null || value === undefined) {
    //   return true
    // }
    // return max.validate(value, { length: 4000 })
  },
  message(field) {
    return __rootT('veevalidaion_messages.short_text', {
      field,
      length: 4000,
    })
  },
})

extend('min_date', {
  params: ['date'],
  validate(value, { date }) {
    if (
      value === null ||
      value === undefined ||
      date === null ||
      date === undefined
    ) {
      return true
    }
    return min_value.validate(value, { min: date })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.date_greater_than', {
      field,
      date: `${Moment.unix(args.date / 1000).format('DD/MM/YYYY hh:mm A')}`,
    })
  },
})

extend('max_date', {
  params: ['date'],
  validate(value, { date }) {
    if (
      value === null ||
      value === undefined ||
      date === null ||
      date === undefined
    ) {
      return true
    }
    return max_value.validate(value, { max: date })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.date_less_than', {
      field,
      date: `${Moment.unix(args.date / 1000).format('DD/MM/YYYY hh:mm A')}`,
    })
  },
})

export function validatePasswordWithPolicies(
  value,
  { policies, getInvalidPolicies = false }
) {
  const invalidPolicies = []
  if (Object.keys(policies).length) {
    value = value || ''
    if (policies.minLength) {
      if (value.length < policies.minLength) {
        if (getInvalidPolicies) {
          invalidPolicies.push('minLength')
        } else {
          return false
        }
      }
    }
    if (policies.enableSpecialChar) {
      if (/[~!@#$%^&*_-]+/.test(value) === false) {
        if (getInvalidPolicies) {
          invalidPolicies.push('enableSpecialChar')
        } else {
          return false
        }
      }
    }
    if (policies.enableAlphabetAndNumber) {
      if (/[a-zA-z]+/.test(value) === false || /[0-9]+/.test(value) === false) {
        if (getInvalidPolicies) {
          invalidPolicies.push('enableAlphabetAndNumber')
        } else {
          return false
        }
      }
    }
    if (policies.enableMixCase) {
      if (/[a-z]+/.test(value) === false || /[A-Z]+/.test(value) === false) {
        if (getInvalidPolicies) {
          invalidPolicies.push('enableMixCase')
        } else {
          return false
        }
      }
    }
  }
  if (getInvalidPolicies) {
    return invalidPolicies
  } else {
    return true
  }
}

extend('password', {
  params: ['policies'],
  validate: validatePasswordWithPolicies,
  message(field, args) {
    return __rootT('veevalidaion_messages.password', {
      field,
    })
  },
})

extend('max_value', {
  params: ['maxLimit'],
  validate(value, { maxLimit }) {
    const fieldValue = typeof value === 'object' ? value.value : value
    if (fieldValue === null || fieldValue === undefined || fieldValue === 0) {
      return true
    }
    return max_value.validate(fieldValue, {
      max: maxLimit === 0 ? 0 : maxLimit || 2100000000,
    })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.max_value', {
      field,
      value: `${args.maxLimit === 0 ? 0 : args.maxLimit || 2100000000}`,
    })
  },
})

extend('min_value', {
  params: ['minLimit', 'minLimitText'],
  validate(value, { minLimit }) {
    const fieldValue = typeof value === 'object' ? value.value : value
    if (fieldValue === null || fieldValue === undefined || fieldValue === 0) {
      return true
    }
    return min_value.validate(fieldValue, {
      min: minLimit === 0 ? 0 : minLimit || -2100000000,
    })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.min_value', {
      field,
      value: args.minLimitText
        ? `${args.minLimitText}`
        : `${args.minLimit === 0 ? 0 : args.minLimit || -2100000000}`,
    })
  },
})

extend('array_min_length', {
  params: ['length'],
  validate(value, { length }) {
    if (value === null || value === undefined || !Array.isArray(value)) {
      return true
    }
    return value.length >= length
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.array_min_length', {
      field,
      value: `${args.length}`,
    })
  },
})

extend('array_max_length', {
  params: ['length'],
  validate(value, { length }) {
    if (value === null || value === undefined || !Array.isArray(value)) {
      return true
    }
    return value.length <= length
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.array_max_length', {
      field,
      value: `${args.length}`,
    })
  },
})

extend('array_qual_length', {
  params: ['length'],
  validate(value, { length }) {
    if (value === null || value === undefined || !Array.isArray(value)) {
      return true
    }
    return value.length === length
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.array_qual_length', {
      field,
      value: `${args.length}`,
    })
  },
})

extend('contact_number', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^\\+?[0-9\\s\\-\\(\\)]{1,20}$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.contact_number', { field })
  },
})

extend('alphanumeric', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^(\\d|\\w)+$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.alphanumeric', { field })
  },
})

extend('lower_case_with_numbers', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^[a-z0-9]+$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.lower_case', { field })
  },
})

extend('new_contact_number', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^[0-9,+,(,), ,/,-]{0,50}$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.contact_number', { field })
  },
})

extend('max_timestamp', {
  params: ['maxLimit', 'maxLimitText'],
  validate(value, { maxLimit }) {
    if (value === null || value === undefined || value === 0) {
      return true
    }
    return max_value.validate(value, {
      max: Moment.duration(maxLimit, 'minutes').asMilliseconds(),
    })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.max_hour', {
      field,
      value: `${args.maxLimitText || args.maxLimit}`,
    })
  },
})

extend('min_timestamp', {
  params: ['minLimit', 'minLimitText'],
  validate(value, { minLimit }) {
    if (value === null || value === undefined || value === 0) {
      return true
    }
    return min_value.validate(value, {
      min: Moment.duration(minLimit, 'minutes').asMilliseconds(),
    })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.min_hour', {
      field,
      value: `${args.minLimitText || args.minLimit}`,
    })
  },
})

extend('port', {
  validate(value) {
    if (value === null || value === undefined || value === 0) {
      return true
    }
    return max_value.validate(value, { max: 65535 })
  },
  message(field, args) {
    return __rootT('veevalidaion_messages.port', {
      field,
      value: `65535`,
    })
  },
})

extend('mac_address', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.mac_address', { field })
  },
})

extend('ipV6', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp(
        '^([0-9A-Fa-f]{0,4}:){2,7}([0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(.|$)){4})$|^(([01]?dd?|2[0-4]d|25[0-5]).){3}([01]?dd?|2[0-4]d|25[0-5])$'
      ),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.ipV6', { field })
  },
})

extend('ip_or_host', {
  validate(value) {
    if (value === null || value === undefined) {
      return true
    }

    return (
      isFQDN(value, {
        require_tld: false,
        allow_underscores: true,
        allow_trailing_dot: false,
      }) || isURL(value)
    )
  },
  message(field) {
    return __rootT('veevalidaion_messages.ip_or_host', { field })
  },
})

extend('system_oid', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^\\.\\d+(\\.\\d+)+$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.system_oid', { field })
  },
})

extend('required_list_with_none', {
  message(field) {
    return __rootT('veevalidaion_messages.required', { field })
  },
  validate: (value) => value && value !== undefined && value.length > 0,
  computesRequired: true,
})

extend('none_value', {
  message(field) {
    return __rootT('veevalidaion_messages.required', { field })
  },
  validate: (value) => (value !== '' && value !== undefined) || value === '',
  computesRequired: true,
})

extend('trim', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^\\S.*?\\S$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.trim', { field })
  },
})

extend('string_without_space', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: /^\S+$/,
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.string_without_space', { field })
  },
})

// extend('ip_range', {
//   params: ['startRange'],
//   validate(value, { startRange }) {
//     if (value && isIP(value) && startRange && isIP(startRange)) {
//       const startRangeInLong = convertIpToNumeric(startRange)
//       const valueInLong = convertIpToNumeric(value)
//       const diff = valueInLong - startRangeInLong
//       if (diff > 0 && diff <= 255) {
//         return true
//       }
//       return false
//     }
//     return true
//   },
//   message(field) {
//     return __rootT('veevalidaion_messages.ip_range', { field })
//   },
// })

extend('ip_range', {
  params: ['startRange'],
  validate(value, { startRange }) {
    if (value && isIP(value) && startRange && isIP(startRange)) {
      return checkValidRange(startRange, value)
    }
    return true
  },
  message(field) {
    return __rootT('veevalidaion_messages.ip_range', { field })
  },
})

extend('duration_days', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^((\\d+\\.(00|50|25|75|0|5))|\\d+)$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.duration_days', { field })
  },
})

extend('minDifferenceTime', {
  params: ['otherValue', 'minDifference'],
  validate: (value, { otherValue, minDifference }) => {
    if (!otherValue) {
      return true
    }
    const startTime = Moment(otherValue, 'hh:mm A')
    const endTime = Moment(value, 'hh:mm A')
    const minutes = endTime.diff(startTime, 'minutes')
    if (
      startTime >= endTime ||
      minDifference === null ||
      minDifference === 0 ||
      minDifference < Math.abs(minutes)
    ) {
      return true
    }
    return false
  },
  message(field) {
    return __rootT('veevalidaion_messages.duration_time')
  },
})

extend('latitude', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp('^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?)$'),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.invalid_latitude', { field })
  },
})

extend('longitude', {
  validate(value) {
    if (value === null || value === undefined || value === '') {
      return true
    }
    return regex.validate(value, {
      regex: new RegExp(
        '^[-+]?((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?|180(\\.0+)?$'
      ),
    })
  },
  message(field) {
    return __rootT('veevalidaion_messages.invalid_longitude', { field })
  },
})

extend('number_negative_value', {
  validate(value) {
    return !value
      ? true
      : regex.validate(value, {
          regex: new RegExp('^[\\-]?([1-9]\\d*|0)?$'),
        })
  },
  message(field) {
    return __rootT('veevalidaion_messages.valid_input_value', { field })
  },
})
