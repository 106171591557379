let TinyColorFn

import(/* webpackChunkName: "utils" */ '@thebespokepixel/es-tinycolor').then(
  (c) => {
    TinyColorFn = c.tinycolor
  }
)

export function getTagStyleFromColor(mainColor, theme) {
  if (TinyColorFn && mainColor) {
    const c = TinyColorFn(mainColor)
    const luminance = c.getLuminance()
    if (theme === 'dark_theme' && luminance >= 0 && luminance <= 0.2) {
      return {
        backgroundColor: 'rgba(255, 255, 255, 0.65)',
        color: mainColor,
        borderColor: 'transparent',
      }
    } else if (theme === 'light_theme' && luminance <= 1 && luminance >= 0.65) {
      return {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        color: mainColor,
        borderColor: 'transparent',
      }
    }
    return {
      backgroundColor: c.setAlpha(0.2).toRgbString(),
      color: mainColor,
      borderColor: 'transparent',
    }
  }
  return {
    backgroundColor: 'var(--dropdown-trigger-icon-bg)',
    color: 'var(--white-regular)',
  }
}

export const colors = {
  primary: '#3279be',
  success: '#89c540',
  error: '#f04e3e',
  warning: '#f58518',
  orange: '#f58518',
  golden: '#ffbf00',
  'neutral-light': '#a5bad0',
  'neutral-lighter': '#dee5ed',
  'neutral-lightest': '#eef2f6',
  default: '#ffffff',
  'warning-light': '#f5bc18',
  'secondary-yellow': '#f5bc18',
}

export const getRandomColor = () => {
  var o = Math.round
  var r = Math.random
  var s = 255
  return `rgba(${o(r() * s)}, ${o(r() * s)}, ${o(r() * s)}, 0.6)`
}

export function hexToRgbA(hex, alpha) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = `0x${c.join('')}`
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ','
    )}, ${alpha})`
  }
  throw new Error('Bad Hex')
}

export function getColorFadeValue(mainColor, alpha) {
  if (TinyColorFn && mainColor) {
    const color = TinyColorFn(mainColor)
    return color.setAlpha(alpha)
  } else {
    return mainColor
  }
}
