import i18n from '@src/i18n'

export function getModuleTranslator(moduleName) {
  return function __t(key, ...args) {
    return i18n.t(`${moduleName ? `${moduleName}.` : ''}${key}`, ...args)
  }
}

export function getModulePluaralTranslator(moduleName) {
  return function __tc(key, ...args) {
    return i18n.tc(`${moduleName ? `${moduleName}.` : ''}${key}`, ...args)
  }
}

export function getRootPluaralTranslator(moduleName) {
  return function __tc(key, ...args) {
    return i18n.tc(`${moduleName ? `${moduleName}.` : ''}${key}`, ...args)
  }
}

export function getRootTranslator() {
  return getModuleTranslator()
}
