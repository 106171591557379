export const localStoreSupport = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null
  } catch (e) {
    return false
  }
}

export const setItem = (name, value) => {
  localStorage.setItem(name, value)
}

export const getItem = (name) => {
  if (localStoreSupport()) {
    const ret = localStorage.getItem(name)
    // console.log(typeof ret);
    switch (ret) {
      case 'true':
        return true
      case 'false':
        return false
      default:
        return ret
    }
  }
}

export const removeItem = (name) => {
  if (localStoreSupport()) {
    localStorage.removeItem(name)
  }
}
