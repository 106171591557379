import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const layoutsRequired = require.context('.', true, /\.vue$/)

layoutsRequired.keys().forEach((fileName) => {
  const requiredModule = layoutsRequired(fileName)
  const layout = requiredModule.default || requiredModule
  const fileComponentName = upperFirst(
    camelCase(
      fileName
        // Remove the "./_" from the beginning
        .replace(/^\.\/_/, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
    )
  )
  const componentName = layout.name || fileComponentName
  Vue.component(componentName, layout)
})
