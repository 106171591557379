<template>
  <div class="h-full w-full flex bg-white p-4">
    <div v-if="noData" class="flex flex-col flex-1">
      <FlotoNoData class="w-full">
        <template v-slot:message>
          {{ $tc('no_chart_data') }}
        </template>
      </FlotoNoData>
    </div>
    <ChartOptions
      v-else
      :key="type"
      :data="data.series"
      :widget-type="type"
      :widget="widget"
      :vertical="vertical"
      :enable-export="false"
      :categories="(data || {}).categories || []"
    >
      <template v-slot="{ options }">
        <Chart
          v-if="options.series.length"
          :options="options"
          class="flex-1 h-full w-full"
        />
        <div v-else class="flex flex-col flex-1 items-center justify-center">
          <MLoader />
          <h5>Building Chart...</h5>
        </div>
      </template>
    </ChartOptions>
    <!-- <Chart
      v-else
      :type="type"
      :data="data"
      :show-pie-legend="true"
      show-legend-count
    /> -->
  </div>
</template>

<script>
import { WidgetTypeConstants } from '@components/chart/helper'
import Chartjs from 'chart.js'
import ChartOptions from '@/src/components/chart/options/chart-options.vue'
import buildChartSeries from '@components/chart/chart-series-builder'
import Chart from '@components/chart/chart'
import { getSurveyRespondePreviewOnlyApi } from '@modules/user-survey/user-survey-api'

Chartjs.defaults.global.defaultFontColor = 'black'

export default {
  name: 'SurveyResponseChartRender',
  components: { Chart, ChartOptions },
  data() {
    return {
      type: undefined,
      data: undefined,
      noData: false,
      widget: {},
    }
  },
  computed: {
    widgetType() {
      const widget = this.widget
      return widget.visulizationType
    },
    WidgetTypeConstants() {
      return WidgetTypeConstants
    },
    isPie() {
      return this.widgetType === WidgetTypeConstants.PIE
    },
    vertical() {
      if ([WidgetTypeConstants.VERTICAL_BAR].indexOf(this.type) >= 0) {
        return true
      }
      return false
    },
  },
  created() {
    const query = this.$route.query
    if (!query.token || !query.widgetId) {
      throw new Error('Params token, widgetId is required')
    }
    this.getQuestionChart(query)
  },
  methods: {
    getQuestionChart(query) {
      return getSurveyRespondePreviewOnlyApi(query).then((data) => {
        this.noData = data.data.noData
        this.widget = Object.freeze(data.widget)
        this.type = query.chartType
        this.data = buildChartSeries(data.data, this.type, {
          widgetId: data.widget.id,
          vm: this,
        })
      })
    },
  },
}
</script>
