<template>
  <Transition appear>
    <h4>Loading</h4>
  </Transition>
</template>

<script>
export default {
  name: 'Loading',
  page: {
    title: 'Loading page...',
    meta: [{ name: 'description', content: 'Loading page...' }],
  },
}
</script>
