import JsBarcode from 'jsbarcode'
import bwipjs from 'bwip-js'
import { generateId } from '@utils/id'
import Chunk from 'lodash/chunk'
import MaxBy from 'lodash/maxBy'
import { errorNotification } from '@motadata/ui'
import { getRootPluaralTranslator } from '@utils/get-module-translator'
import dateTimeFilter from '@src/filters/datetime'
import { flattenRecursive } from '@data/recursive'
const __tc = getRootPluaralTranslator()

// 1 inch = 96px
const inchToPx = (inch) => inch * 96
const pxToInch = (px) => px / 96

const DOTTED_SEGMENT_LENGTH = 6

const WIDTH = 136
const HEIGHT = 72

const defaultOptions = {
  height: 50,
  displayValue: true,
  margin: 0,
  marginBottom: 3,
}

const dottedLine = (
  doc,
  xFrom,
  yFrom,
  xTo,
  yTo,
  segmentLength = DOTTED_SEGMENT_LENGTH
) => {
  // Calculate line length (c)
  const a = Math.abs(xTo - xFrom)
  const b = Math.abs(yTo - yFrom)
  // eslint-disable-next-line
  const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2))

  // Make sure we have an odd number of line segments (drawn or blank)
  // to fit it nicely
  const fractions = c / segmentLength
  const adjustedSegmentLength =
    Math.floor(fractions) % 2 === 0
      ? c / Math.ceil(fractions)
      : c / Math.floor(fractions)

  // Calculate x, y deltas per segment
  const deltaX = adjustedSegmentLength * (a / c)
  const deltaY = adjustedSegmentLength * (b / c)

  let curX = xFrom
  let curY = yFrom
  while (curX <= xTo && curY <= yTo) {
    doc.line(
      pxToInch(curX),
      pxToInch(curY),
      pxToInch(curX + deltaX),
      pxToInch(curY + deltaY)
    )
    curX += 2 * deltaX
    curY += 2 * deltaY
  }
}

export const imagePositioner = (
  doc,
  images,
  horizontalMargin = 0,
  verticalMargin = 0
) => {
  const pageWidth = inchToPx(doc.internal.pageSize.width)
  const pageHeight =
    inchToPx(doc.internal.pageSize.height) - verticalMargin - verticalMargin
  let startXPosition = horizontalMargin
  let startYPosition = verticalMargin
  let chunkSize = 0
  let startPosition = startXPosition
  const imageWidth = MaxBy(images, 'width').width
  const imageHeight = MaxBy(images, 'height').height
  while (startPosition + imageWidth < pageWidth) {
    startPosition += imageWidth + horizontalMargin
    chunkSize++
  }
  const halfXMargin = horizontalMargin / 2
  const halfYMargin = verticalMargin / 2
  doc.setLineWidth(pxToInch(2))
  doc.setDrawColor(200, 205, 206)
  Chunk(images, chunkSize).forEach((chunk) => {
    if (startYPosition + imageHeight >= pageHeight) {
      doc.addPage()
      doc.setLineWidth(pxToInch(2))
      doc.setDrawColor(200, 205, 206)
      startXPosition = horizontalMargin
      startYPosition = verticalMargin
    }
    chunk.forEach((i, index) => {
      if (index === 0) {
        dottedLine(
          doc,
          startXPosition - halfXMargin,
          startYPosition - halfXMargin,
          startXPosition - halfXMargin,
          startYPosition + imageHeight + halfYMargin
        )
      }
      doc.addImage(
        i.image,
        'png',
        pxToInch(startXPosition),
        pxToInch(startYPosition)
      )
      dottedLine(
        doc,
        startXPosition - halfXMargin,
        startYPosition + imageHeight + halfYMargin,
        startXPosition + imageWidth + (halfXMargin - DOTTED_SEGMENT_LENGTH),
        startYPosition + imageHeight + halfYMargin
      )
      startXPosition += imageWidth + horizontalMargin
      dottedLine(
        doc,
        startXPosition - halfXMargin,
        startYPosition - halfYMargin,
        startXPosition - halfXMargin,
        startYPosition + imageHeight + (halfYMargin - DOTTED_SEGMENT_LENGTH)
      )
    })
    startYPosition += imageHeight + verticalMargin
    startXPosition = horizontalMargin
  })
  return doc
}
export const generateWithDimensions = (barcode, options) => {
  let canvas = document.createElement('canvas')
  canvas.id = generateId()
  JsBarcode(canvas, barcode, { ...defaultOptions, ...(options || {}) })
  let resizeCanvas = document.createElement('canvas')
  resizeCanvas.height = HEIGHT
  resizeCanvas.width = WIDTH
  const ctx = resizeCanvas.getContext('2d')
  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, resizeCanvas.width, resizeCanvas.height)
  if (canvas.width < resizeCanvas.width) {
    const diff = resizeCanvas.width - canvas.width
    ctx.drawImage(canvas, diff / 2, 0)
  } else {
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 132, 72)
  }
  // Use the resized image to do what you want
  const image = resizeCanvas.toDataURL('png')
  canvas = null
  resizeCanvas = null
  return { image, width: WIDTH, height: HEIGHT }
}

// export const generateWithConfig = (barcode, options, width, height) => {
//   let canvas = document.createElement('canvas')
//   canvas.id = generateId()
//   JsBarcode(canvas, barcode, { ...(options || {}) })
//   let resizeCanvas = document.createElement('canvas')
//   resizeCanvas.height = height
//   resizeCanvas.width = width
//   const ctx = resizeCanvas.getContext('2d')
//   ctx.fillStyle = '#dee5ed'
//   ctx.fillRect(0, 0, resizeCanvas.width, resizeCanvas.height)
//   // if (canvas.width < resizeCanvas.width) {
//   //   const diff = resizeCanvas.width - canvas.width
//   //   ctx.drawImage(canvas, diff / 2, 0)
//   // } else {
//   //   ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, 132, 72)
//   // }
//   ctx.drawImage(canvas, 0, 0, width, height)
//   // ctx.drawImage(canvas, 0, 0, width, height)
//   // Use the resized image to do what you want
//   const image = resizeCanvas.toDataURL('png')
//   canvas = null
//   resizeCanvas = null
//   return { image, width: WIDTH, height: HEIGHT }
// }

export const generateWithConfig = (barcode, options) => {
  const c = document.createElement('canvas')
  let canvas = bwipjs.toCanvas(c, {
    bcid: 'code128', // Barcode type
    text: barcode, // Text to encode
    scale: 15, // 3x scaling factor
    height: 20, // 20, // Bar height, in millimeters
    padding: 5,
    // rotate: 'R',
    includetext: true, // Show human-readable text
    textxalign: 'center', // Always good to set this,
    ...options,
  })
  const image = canvas.toDataURL('img/png')
  return image
}

export const generate = (barcode, options) => {
  let canvas = document.createElement('canvas')
  canvas.id = generateId()
  JsBarcode(canvas, barcode, { ...defaultOptions, ...(options || {}) })
  const imageString = canvas.toDataURL('png')
  canvas = null // free up memory
  return imageString
}

export const gotToPrint = (data) => {
  if (data.barcodes) {
    const b = JSON.stringify(data.barcodes)
    window.open(
      `/print-barcode?barcodes=${b}&moduleName=${data.moduleName}`,
      'targetWindow',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=800,height=800'
    )
  } else {
    const id = JSON.stringify(data.assetIds)
    window.open(
      `/print-barcode?assetIds=${id}&moduleName=${data.moduleName}`,
      'targetWindow',
      'toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=800,height=800'
    )
  }
}

export const printAllBarcodes = (
  barcodes = [],
  returnBlob = false,
  fileName = false
) => {
  const images = barcodes.map((b) => generateWithDimensions(b, { width: 1 }))
  return new Promise((resolve, reject) => {
    import('jspdf')
      .then((Pdf) => {
        Pdf = Pdf.default || Pdf
        const doc = new Pdf('p', 'in', 'a4')
        doc.autoPrint()
        imagePositioner(doc, images, 46, 50)
        if (returnBlob) {
          return resolve(doc.output('blob'))
        }
        doc.save(fileName || 'barcodes.pdf')
        resolve()
      })
      .catch(reject)
  })
}

export const printBarcode = (barcode) => {
  printAllBarcodes([barcode], false, `barcode-${barcode}.pdf`)
}

export const getAssetName = (a) => `${a.name}: ${a.displayName}`

export const getCsv = (assets) => {
  const headers = [__tc('asset'), '', __tc('barcode')]
  let csvString = `${headers.join(',')}\n`
  csvString += assets.reduce(
    (s, a) => (s += `${[getAssetName(a), '', a.barCode].join(',')}\n`),
    ''
  )
  return csvString
}

export const generateBarcodeZip = (assets) => {
  if (assets.length === 0) {
    return Promise.reject(
      errorNotification(
        {
          message: 'error message here',
          description: 'error description here',
        },
        'error'
      )
    )
  }
  // showLoader(0, __translate('asset:generatingzipfileofassetbarcodes'))
  let JsZip
  return import('jszip')
    .then((zipModule) => {
      JsZip = zipModule.default || zipModule
      return printAllBarcodes(
        assets.map((a) => a.barCode),
        true
      )
    })
    .then((pdfBlob) => {
      gotToPrint({
        assetIds: assets.map((a) => a.id),
        moduleName: (assets[0] || {}).model,
      })
      return pdfBlob
    })
    .then((pdfBlob) => {
      const zip = new JsZip()
      zip.file('barcodes.pdf', pdfBlob)
      zip.file('assets.csv', getCsv(assets))
      return zip.generateAsync({ type: 'blob' })
    })
    .then((blob) => {
      import('file-saver').then((FS) => FS.saveAs(blob, 'assets-barcodes.zip'))
      // hideLoader()
    })
    .catch((e) => {
      // console.log(e)
      // hideLoader()
    })
}

export function prepareAssetForBarcodeCode(assets, dependentData) {
  let p = Promise.resolve()

  return p.then(() => {
    return assets.map((asset) => {
      const customFieldsTransform = {}
      if (dependentData.datetimeFields) {
        dependentData.datetimeFields.forEach((field) => {
          customFieldsTransform[field.id] = dateTimeFilter(
            asset[field.id],
            undefined,
            field.attributes.allowTime
          )
        })
      }
      if (dependentData.dependentFields) {
        dependentData.dependentFields.forEach((field) => {
          const flattenedTree = flattenRecursive(field.tree)
          customFieldsTransform[field.id] = (
            flattenedTree.find((o) => o.id === asset[field.id]) || {}
          ).name
        })
      }
      return {
        ...asset,
        ...customFieldsTransform,
      }
    })
  })
}
