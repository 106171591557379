import Constants from '@constants'
import { isPortalLogin } from '@utils/auth'

export function getDetailLinkForModule(model, id, modules, type, query = {}) {
  if (type === 'approval') {
    return {
      link: isPortalLogin()
        ? modules.getModuleRoute('support-portal', 'view-approval', {
            params: { id },
          })
        : modules.getModuleRoute('my-approval', 'view', {
            params: { id },
          }),
    }
  }
  if (
    [
      Constants.REQUEST,
      Constants.PROBLEM,
      Constants.CHANGE,
      Constants.RELEASE,
    ].indexOf(model) >= 0
  ) {
    return {
      link: isPortalLogin()
        ? modules.getModuleRoute('support-portal', 'view-request', {
            params: { id },
            query,
          })
        : modules.getModuleRoute('ticket', 'view', {
            params: { id, ticketType: model },
            query,
          }),
    }
  }
  if (
    [
      Constants.ASSET_HARDWARE,
      Constants.ASSET_SOFTWARE,
      Constants.ASSET_NON_IT,
      Constants.ASSET_CONSUMABLE,
    ].indexOf(model) >= 0
  ) {
    return {
      link: isPortalLogin()
        ? modules.getModuleRoute('support-portal', 'my-assets', {
            params: { assetType: Constants.ASSET },
          })
        : modules.getModuleRoute('asset', 'view', {
            params: { id, assetType: model },
          }),
    }
  }
  if ([Constants.CMDB].indexOf(model) >= 0) {
    return {
      link: modules.getModuleRoute('cmdb', 'view', {
        params: { id, ciType: model },
      }),
    }
  }
  if (model === Constants.SOFTWARE_LICENSE) {
    return {
      link: modules.getModuleRoute('software-license', 'view', {
        params: { id },
      }),
    }
  }
  if (model === Constants.CONTRACT) {
    return {
      link: modules.getModuleRoute('contract', 'view', {
        params: { id },
      }),
    }
  }
  if (model === Constants.PURCHASE) {
    return {
      link: modules.getModuleRoute('purchase', 'view', {
        params: { id },
      }),
    }
  }
  if (model === Constants.TASK) {
    return {
      link: modules.getModuleRoute('my-tasks', 'view', {
        params: { id },
      }),
    }
  }
  if (model === Constants.PROJECT) {
    return {
      link: modules.getModuleRoute('project', 'view', {
        params: { id },
      }),
    }
  }
  if (model === Constants.KNOWLEDGE) {
    return {
      link: isPortalLogin()
        ? modules.getModuleRoute('support-portal', 'read', {
            params: { id },
          })
        : modules.getModuleRoute('knowledge', 'read', {
            params: { id },
          }),
    }
  }
  if (model === Constants.SERVICE_CATALOG) {
    return {
      link: isPortalLogin()
        ? modules.getModuleRoute('service-catalog', 'edit', {
            params: { id },
            query,
          })
        : modules.getModuleRoute('service-catalog', 'edit', {
            params: { id },
            query,
          }),
    }
  }
  return {}
}
