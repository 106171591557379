<template>
  <MRow class="flex flex-1 -mt-4 h-100">
    <MCol class="flex flex-1 flex-col mb-4">
      <MRow class="justify-center" style="height: 30vh">
        <MCol class="flex flex-1">
          <div class="left-top-dots" />
        </MCol>
        <MCol :size="4" />
        <MCol class="flex flex-1">
          <div class="right-top-dots" />
        </MCol>
      </MRow>
      <MRow class="flex-1 items-center justify-center">
        <MCol :size="12" style="margin-top: -30vh">
          <h2 class="text-center font-normal">
            <b>{{ $tc('success') }}! </b>
            {{
              $route.query.message
                ? $route.query.message
                : $tc('operation_completed')
            }}
          </h2>
          <div class="text-center">
            <FlotoLink as-button :to="homeRoute">
              {{ $t('go_to_home') }}
            </FlotoLink>
          </div>
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import { authComputed } from '@state/modules/auth'

export default {
  name: 'System200',
  page: {
    title: '200',
    meta: [{ name: 'description', content: '200' }],
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...authComputed,
    homeRoute() {
      if (this.isPortalLogin) {
        return this.$modules.getModuleRoute('support-portal')
      }
      return this.$modules.getModuleRoute('dashboard')
    },
  },
}
</script>
