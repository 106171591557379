<template>
  <div>
    <div v-html="pageContent"></div>
  </div>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import {
  elementIDs,
  onGoToHomeButtonClick,
  displayMessage,
} from '@src/js/static-page-script'
export default {
  name: 'Custom200',
  props: {
    pageContent: { type: String, default: undefined },
  },
  computed: {
    ...authComputed,
    homeRoute() {
      if (this.isPortalLogin) {
        return this.$modules.getModuleRoute('support-portal')
      }
      return this.$modules.getModuleRoute('dashboard')
    },
  },
  mounted() {
    setTimeout(() => {
      onGoToHomeButtonClick().then(() => {
        this.$router.push(this.homeRoute)
      })
      if (this.$route.query.message) {
        displayMessage(this.$route.query.message, elementIDs.API_SUCESS_MSG)
      }
    }, 2000)
  },
}
</script>
