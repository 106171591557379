<template>
  <div class="h-100">
    <System500 v-if="!isCustom" v-bind="$attrs" v-on="$listeners" />
    <Custom500
      v-if="isCustom"
      :page-content="pageContent"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import System500 from './_500/system-500.vue'
import Custom500 from './_500/custom-500.vue'
import BrandingMixin from '@src/mixins/branding'
import { QUERY_STRING_TEXT } from '@src/js/static-page-script'

export default {
  name: 'Error500',
  components: { System500, Custom500 },
  mixins: [BrandingMixin],
  page: {
    title: '500',
    meta: [{ name: 'description', content: '500' }],
  },
  data() {
    return {
      isCustom: false,
      pageContent: undefined,
    }
  },
  watch: {
    'branding.pagesCustomization': {
      immediate: true,
      handler(newValue) {
        if (
          this.$route.query[QUERY_STRING_TEXT] &&
          this.$route.query[QUERY_STRING_TEXT] === 'true'
        ) {
          return
        }
        const currentPage = newValue.find((v) => v.pageType === 'page_500')
        if (currentPage.enabled && currentPage.pageContent) {
          this.isCustom = true
          this.pageContent = currentPage.pageContent
        }
      },
    },
  },
}
</script>
