<template>
  <div> </div>
</template>

<script>
import { load } from 'recaptcha-v3'

export default {
  name: 'MobileCaptchaRenderer',
  data() {
    return {
      token: '',
      recaptcha: undefined,
    }
  },
  created() {
    this.getCaptchaToken(this.$route.query.site_key)
  },
  methods: {
    getCaptchaToken(key) {
      load(key).then((recaptcha) => {
        this.recaptcha = recaptcha
        recaptcha.execute('submit').then((token) => {
          this.token = token
          window.Recaptcha.postMessage(token)
          return token
        })
      })
    },
  },
}
</script>
