export function copyToClipboard(text) {
  const value = text
  if (window.clipboardData && window.clipboardData.setData) {
    return window.clipboardData.setData('Text', value)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea')
    textarea.textContent = value
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('copy to clipboard failed', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}
