import CryptoJS from 'crypto-js'
const secretKey = 'TbFx6GA=H_VyRqu6%@VwBn9mGfAS_wc&'
// const secretKey = '8r1abkds5h1i8t3s4a4v4sdabndi748r'
const isEnabled = true
export const getEncryptedPassword = (value) => {
  if (isEnabled) {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value),
      CryptoJS.enc.Utf8.parse(secretKey),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    ).toString()
  }
  return value
}
export const getDecryptedPassword = (ciphertext) => {
  if (isEnabled && ciphertext) {
    const bytes = CryptoJS.AES.decrypt(
      ciphertext,
      CryptoJS.enc.Utf8.parse(secretKey),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    )
    const originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
  }
  return ciphertext
}
