<template>
  <h1 class="text-center">
    The page timed out while loading. Are you sure you're still connected to the
    Internet?
  </h1>
</template>

<script>
export default {
  page: {
    title: 'Page timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
}
</script>
