/**
 * Convert json data to query string
 * @param {object} data
 * @returns {string}
 */
export function stringify(data) {
  return Object.entries(data)
    .map((x) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&')
}
